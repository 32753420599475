import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/templates/blog.scss'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Container, Row, Col, Nav, Navbar, NavbarBrand, NavDropdown, Image, Form, Input } from "react-bootstrap"
import config from '../utils/siteConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import PublishedDateLabel from '../components/Atoms/resourcesDetail/PublishedDateLabel/PublishedDateLabel'
import CategoryLabel from '../components/Atoms/resourcesDetail/CategoryLabel/CategoryLabel'
import AuthorLabel from '../components/Atoms/resourcesDetail/AuthorLabel/AuthorLabel'
import TagLabel from '../components/Atoms/resourcesDetail/TagLabel/TagLabel'
import SourceLinkLabel from '../components/Atoms/resourcesDetail/SourceLinkLabel/SourceLinkLabel'

import Button from "../components/Atoms/Button/Button"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import Highlight from "../components/Molecules/Highlight/Highlight"
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";


const BlogTemplate = ({ data, pageContext }) => {
  debugger;

  const page = data.contentfulBlog
  const blogPage = data.allContentfulZBlog.edges[0].node

  const blogs = data.allContentfulBlog.edges
  const optionsCategoryTypes = data.allContentfulCategory.distinct
  if (!data.allContentfulCategory.distinct.some(value => (value.title === 'All'))) {
    optionsCategoryTypes.unshift('All')
  }

  const allData = []

  const Bold = ({ children }) => <span className="bold">{children}</span>
  //const Text = ({ children }) => <p className="align-center">{children}</p>;


  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="heading1">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="heading2">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="heading3">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="section10Bold">{children}</h4>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="p-medium3">{children}</p>
      ),
      [BLOCKS.UL_LIST]: (node, children) => {
        return (
          <ul
            style={{
              listStyle: 'disc',
              lineHeight: '35px',
              marginLeft: '1rem',
            }}
          >
            {children.map((item) => (
              <li key={item.key}>{item.props.children[0].props.children[0]}</li>
            ))}
          </ul>
        );
      },
      //[BLOCKS.UL_LIST]: (node, children) => (
      //    <span className="d-inline-block bulletSpan">{children}</span>
      //),

    },
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderText: (text) =>
      text
        .replace(/\u2028/g, "")
        .split("\n")
        .flatMap((text, i) => [i > 0 && <br />, text])
  }

  const {
    publishDate,
    publisher,
    body,
    tags,
    categories,
    metaData,
    id,
    headline,
    headerImage,
    contentType,
    previewImage
  } = page


  const resource = page

  return (
    <Layout
      IsHeaderImageDark={false}
      navColour={"white"}>

      <head>
        <title>{metaData.title}</title>
        <meta charset="utf-8" />
        <meta name="description" content={metaData.description} />
      </head>

      <section>
        <div className="sectionFit-container3 bg-white">
          <Row>
            <div class="pt-3 text-left pl-1">
              <a className="p-regular3 pr-2" href={config.navigationURL_blog}>
                {contentType}
              </a>
              <span className="p-regular3 text-lightgray">{"/"}</span>
              {categories.map(category => (
                <a className="pl-2 p-regular3" href={config.navigationURL_blog}>{category.title}</a>
                // {`/tag/${tag.slug}/`}
              ))}
            </div>
          </Row>

          {/* heading */}
          <Row className="pt-5 pb-4">
            <Col lg="3" className="pt-5">
            </Col>
            <Col lg="9" className="pt-4">
              <h1 className="h1-largeMedium2 h1-darkBlue">{headline.headline}</h1>
              <p class="pt-4">
                <span> {publishDate}</span>
              </p>
            </Col>

          </Row>

          {/* body */}
          <Row>
            <Col lg="3" className="pl-1">
              <p class="pb-4">
                <h3 className="pb-1 h3-small h1-darkBlue3"><AuthorLabel></AuthorLabel></h3>
                <a> <h3 className="pb-1 p-regular4 text-darkgray5">{publisher}</h3></a>
              </p>
            </Col>
            <Col lg="9" >
              <div class="pt-5">
                <Image src={headerImage.file.url} fluid alt="" />
                <p class="pt-5 pb-3 p-space3">
                  {documentToReactComponents(body.json)}
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="3">
            </Col>
            <Col lg="9" >
              <div class="mt-5">
                <p class="pb-5">
                  <h6 className="pb-3"><TagLabel /></h6>
                  <div className="row pl-3">
                    {tags.map(tag => (
                      <div className="pr-3">
                        <Button
                          className='btns'
                          buttonStyle='btn--outlinePurple'
                          buttonSize='btn--small'
                          href={config.navigationURL_blog}
                        >
                          {tag.title}
                        </Button>
                      </div>
                    ))}
                  </div>
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="head-container bg-white">
          <div className="head-canvas bg-white">
            <div className="row pt-4">
              <h1 className="align-top h1-mediumSmall h1-darkBlue pl-2 pb-4">
                You may also like:
              </h1>
            </div>
            <div className="d-flex flex-row flex-nowrap">
              {blogs.map((resource, index) => (
                (resource.node.headline.headline == headline.headline) ?
                  (null) :
                  (<Highlight key={resource.node.id} index={index} {...blogPage} {...resource.node} featured />)

              ))}
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export const query = graphql`
query($slug: String!, $locale: String!) {
  contentfulBlog(
    metaData: {
    slug: { eq: $slug }
  },
    node_locale: { eq: $locale }) {
    id
    contentType
    metaData {
      slug
      title
      description
      keywords {
        keywords
      }
    }
    headerImage {
      file {
        url
        fileName
      }
    }
    publisher
    publishDate(formatString: "MMMM DD, YYYY")
    categories {
      id
      slug
      title
    }
    tags {
      id
      slug
      title
    }
    headline {
      headline
    }
    body {
      body
      json
    }
    previewImage {
      file {
        url
        fileName
      }
    }

  }

  allContentfulZBlog(
    filter: {
    node_locale: { eq: $locale }
  }) {
    edges {
      node {
        id
        metaData {
          slug
          title
          description
          keywords {
            keywords
          }
        }
        bgFBtn
        bgFBtn2
        bgFHd
        bgFHd2
        bgFRtxt {
          json
        }
        bgFTxt
        bgFTxt2
        bgHBtn
        bgHBtn2
        bgHHd

      }
    }
  }

  allContentfulCategory(
    filter: {
    node_locale: { eq: $locale }
  },
    sort: { fields: title, order: ASC }
  ) {
    distinct(field: title)
    nodes {
      slug
      title
      id
    }
  }

  allContentfulBlog(
    filter: {
    node_locale: { eq: $locale }
  },
    sort: { fields: [publishDate], order: DESC }
  ) {
    edges {
      node {
        id
        contentType
        metaData {
          title
          slug
        }
        headerImage {
          file {
            url
            fileName
          }
        }
        publishDate(formatString: "MMMM DD, YYYY")
        categories {
          id
          slug
          title
        }
        tags {
          id
          slug
          title
        }
        headline {
          headline
        }
        abstract {
          abstract
        }
        previewImage {
          file {
            url
            fileName
          }
        }
        publisher
      }
    }
  }

}
`

export default BlogTemplate
